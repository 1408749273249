import { render, staticRenderFns } from "./CompaniesCards.vue?vue&type=template&id=34b3da08&scoped=true"
import script from "./CompaniesCards.vue?vue&type=script&lang=js"
export * from "./CompaniesCards.vue?vue&type=script&lang=js"
import style0 from "./CompaniesCards.vue?vue&type=style&index=0&id=34b3da08&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34b3da08",
  null
  
)

export default component.exports