import { render, staticRenderFns } from "./AppCardUser.vue?vue&type=template&id=508dec4e&scoped=true"
import script from "./AppCardUser.vue?vue&type=script&lang=js"
export * from "./AppCardUser.vue?vue&type=script&lang=js"
import style0 from "./AppCardUser.vue?vue&type=style&index=0&id=508dec4e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "508dec4e",
  null
  
)

export default component.exports